import LinkRoutes from "constants/LinkRoutes"

export const CARDS_LINK = [
  {
    title: 'Abre tu Depósito a Plazo Fijo',
    icon: 'pig.svg',
    isNew: true,
    link: LinkRoutes.dpf
  },
  {
    title: 'Paga la cuota de tu crédito',
    icon: 'dollar.svg',
    isNew: false,
    link: LinkRoutes.channels
  },
  {
    title: 'Crédito Mujeres unidas',
    icon: 'persons.svg',
    isNew: false,
    link: LinkRoutes.creditWomen
  },
  {
    title: 'Crédito Prospera',
    icon: 'home.svg',
    isNew: false,
    link: LinkRoutes.creditProsperous
  },
  {
    title: 'Crédito Construyendo Sueños',
    icon: 'store.svg',
    isNew: false,
    link: '/credito-prospera#construyendo-sueños'
  },
  {
    title: 'Promociones y sorteos',
    icon: 'gift.svg',
    isNew: false,
    link: LinkRoutes.promotionHistory
  }
]

export const ITEMS_ABOUT = [
  {
    title: 'Servicio personalizado',
    description: 'Con soluciones financieras y asesoría personalizada que se adapta a tus necesidades.',
    icon: 'friends.svg'
  },
  {
    title: 'Ponemos en valor tu tiempo',
    description: 'Servicios financieros accesibles desde tu hogar o negocio, sin muchos trámites, ni papeleos.',
    icon: 'clock.svg'
  },
  {
    title: 'Contribuimos al progreso',
    description: 'Apoyamos a miles de personas en el crecimiento y progreso para alcanzar sus sueños.',
    icon: 'hands.svg'
  }
]

export const PRODUCTS_AHORROS = [
  {
    hero: 'dpf-product.png',
    title: 'Depósitos a Plazo Fijo',
    description: 'Comienza a rentabilizar tu dinero con hasta 6.00% TREA en soles.',
    alt: 'Alt_cumple tus metas con nuestra súper tasa ahorros inversión SURGIR depósito a plazo fijo card home',
    href: LinkRoutes.dpf,
    textStrong: '6.00%'
  },
  {
    hero: 'dpff-product.png',
    title: 'Depósitos a Plazo Fijo Flexible',
    description: 'Incrementa y retira tu capital, sin afectar tu tasa.',
    alt: 'Alt_metas ahorros inversión rentabilidad SURGIR depósito a plazo fijo flexible card home',
    href: LinkRoutes.flexibleFixedDeposit
  },
  {
    hero: 'cuenta-ahorros.png',
    title: 'Cuenta de Ahorros',
    description: 'Abona tus intereses periódicos de tu Depósito a Plazo Fijo.',
    alt: 'Cuenta de Ahorros',
    href: LinkRoutes.savingsAccount
  }
]

export const PRODUCTS_CREDITOS = [
  {
    hero: 'credito-mujeres-unidas.png',
    title: 'Crédito Mujeres Unidas',
    description: 'Un crédito que da capital de trabajo a un grupo de mujeres emprendedoras.',
    alt: 'Crédito Mujeres Unidas',
    href: LinkRoutes.creditWomen
  },
  {
    hero: 'credito-prospera.png',
    title: 'Crédito Prospera',
    description: 'Crece tu negocio con capital de trabajo y activo fijo.',
    alt: 'Crédito Prospera',
    href: LinkRoutes.creditProsperous
  },
  {
    hero: 'credito-construyendo.png',
    title: 'Crédito Construyendo Sueños',
    description: 'Construye o amplia tu negocio sin sacrificar tu capital de trabajo.',
    alt: 'Crédito Construyendo Sueños',
    href: '/credito-prospera#construyendo-sueños'
  }
]

export const PRODUCTS_SEGUROS = [
  {
    hero: 'super-seguro.png',
    title: 'Super Seguro',
    description: 'Protege a tu familia con nuestro seguro de vida optativo desde S/ 15.00 al mes.',
    alt: 'Super Seguro',
    href: LinkRoutes.safe
  },
  {
    hero: 'seguro-desgravamen-porcentual.png',
    title: 'Seguro Desgravamen Porcentual',
    description: 'Protege a tu familia en caso de invalidez por accidente o fallecimiento.',
    alt: 'Seguro Desgravamen Porcentual',
    href: LinkRoutes.creditInsurancePercentage
  }
]

export const CARDS_YOUTUBE = [
  {
    background: 'video-1.png',
    idVideo: 'https://www.youtube.com/embed/YuHjNWCz7Mc?si=_6bK7Nm0v65S_0zV'
  },
  {
    background: 'video-2.png',
    idVideo: 'https://www.youtube.com/embed/g2DTdy2cs8k?si=rG2mN00PbYm3ui7L'
  },
  {
    background: 'video-3.png',
    idVideo: 'https://www.youtube.com/embed/qQCdBwRNToQ?si=-wz2JxdmwEENK1SP'
  }
]